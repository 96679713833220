<template>
  <div>
    <div class="font-cg w-3/6 md:w-auto m-auto text-sm md:text-xl text-center pt-5 md:pt-20">
      Welcome to view warm and sincere photos in my portfolio
    </div>
    <Loader v-if="showSuccess" />

    <!-- <div v-else class="grid grid-cols-1 md:grid-cols-4 gap-5 md:gap-10 lg:gap-15 w-screen lg:w-3/4 2xl:w-1/2 mx-auto py-5 md:py-10"> -->
    <div v-else class="flex flex-col md:flex-row w-screen lg:w-3/4  mx-auto py-5 md:py-10">
      <div v-for="cat in categories" :key="cat.id" class="mb-5 md:mb-0 md:w-2/6">
        <router-link
          :to="{
            name: 'categoryCollections',
            params: { categoryId: cat.id },
          }"
        >
          <div class="card card-zoom">
            <img
              class="card-zoom-image"
              :src="cat.image"
            />
            <h2 class="card-zoom-text text-33px">
              {{ cat.name }}
            </h2>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/ImageLoader"
import { mapGetters } from "vuex"
export default {
  name: "portfolio",
  components: {Loader},
  data() {
    return {
      showSuccess: false,
    }
  },
  computed: {
    ...mapGetters(["categories"]),
  },
  async created() {
    await this.$store.dispatch("getCategories").then(() => {
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 1000);
    })

  },
};
</script>

<style lang="postcss">
.card {
  @apply shadow-lg relative h-33rem;
}
.card-zoom {
  @apply flex items-center justify-center m-0 md:m-3 overflow-hidden;
}
.card-zoom-image {
  @apply absolute w-full h-full transition-all duration-500 ease-in-out transform object-cover;
}
.card-zoom-text {
  @apply absolute font-semibold transition-all duration-500 ease-in-out transform scale-150 text-white capitalize font-cg;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-125;
}
.card-zoom:hover .card-zoom-text {
  @apply scale-150;
}
</style>
