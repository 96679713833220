<template>
  <div class="max-w-sm w-full mx-auto">
      <div class="animate-pulse flex justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-80 w-1/2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
    </div>
</template>

<script>
export default {
  name: 'loader',
  // props: {
  //   showSuccess: {
  //     type: 'boolean'
  //   }
  // }
}
</script>

<style>

</style>